import React from 'react';
import styled from 'styled-components';

const PageWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding: 40px 0;
	font-size: 25px;
	line-height: 30px;

	.info {
		max-width: 1600px;
		flex: 2 2 20em;
		flex-grow: 1;
		padding: 20px 0;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		> button {
			margin-left: 50px;
		}
	}

	.content {
		padding: 70px 150px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: var(--primary-color);
			font-size: var(--heading-fontSize);
			display: block;
		}

		p {
			font-size: var(--body-fontSize);
		}

		@media only screen and (max-width: 1000px) {
			padding: 20px 2rem;
		}
	}

	.sidebar {
		flex: 1 1 0.5em;
		flex-shrink: 1;

		img {
			float: right;
			min-width: 250px;
			max-width: 500px;
			// border-radius: 10px;
			width: 100%;
			height: 100%;
			min-height: 100%;
			object-fit: cover;
			box-shadow: -11px 0px 7px 0px rgba(0, 0, 0, 0.16);

			@media only screen and (max-width: 766px) {
				float: none;
				max-width: 100%;
			}
		}
	}

	img {
		width: 100%;
		height: auto;
		max-height: 400px;
		object-fit: contain;
		margin: 0 auto;
	}

	a {
		width: 100%;
		max-width: 500px;
		line-height: 40px;
		background-color: var(--primary-color);
		border-radius: 10px;
		color: var(--alt-color);
		text-decoration: none;
		font-size: var(--action-fontSize);
		padding: var(--action-padding);
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	hr {
		border: 1px solid var(--secondary-color);
		background-color: var(--secondary-color);
	}

	blockquote {
		border-left: 4px solid var(--secondary-color);
		padding-left: 10px;
		padding-right: 10px;
	}

	@media only screen and (max-width: 450px) {
		padding-top: 0;

		.info {
			padding-top: 0;
		}
	}
`;

const Therapy = ({ children }) => {
	return <PageWrapper>{children}</PageWrapper>;
};

export default Therapy;
