import React from 'react';
import { graphql, navigate } from 'gatsby';
import RichText from "../components/richText";
import SEO from '../components/seo';
import { default as PageEl } from '../components/common/layouts/therapy';

export const query = graphql`
	query WorkshopQuery($id: String) {
		prismic {
			allWorkshops(id: $id) {
				edges {
					node {
						heading
						subheading
						image
						description
					}
				}
			}
		}
	}
`;

const Workshop = (props) => {
	const data = props?.data?.prismic?.allWorkshops?.edges[0]?.node;
	if(!data){
		return null;
	}
	return (
		<>
			{/* <SEO title={props.data.prismic.allTherapys.edges[0].node.seo_title}/> */}
			<PageEl>
				<div className="content">
					<button onClick={()=>{
						navigate("/workshop");
					}}>All workshops</button>
					<RichText render={data.heading}/>
					<RichText render={data.subheading}/>
					<RichText render={data.description}/>
				</div>
				<div className="sidebar">
					<img src={data.image.url} alt={data.heading}/>
					<p>Extra Info</p>
					<button>Buy Button</button>
				</div>
			</PageEl>
		</>
	);
};

export default Workshop;
